import React from "react";

import PropTypes from "prop-types";

import * as styles from "./ContentContainer.module.scss";

const ContentContainer = ({ children }) => (
  <div className={styles.contentContainer}>
    <div className={styles.col}>
      {children}
    </div>
  </div>
);

ContentContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ContentContainer;
